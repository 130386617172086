<template>
  <div>
    <Index>
      <div v-wechat-title="$route.meta.title"></div>
      <div class="main">
        <div
          v-for="item in allToolsCategory"
          :key="item.id"
          class="tools_categroy"
        >
          <!-- 标题 -->
          <div class="tools_categroy_top">
            <div class="category_name_left"></div>
            <span class="category_name">{{item.tools_category_name}}</span>
          </div>
          <!-- 分割线 -->
          <hr class="category_hr" />
          <!-- 工具 -->
          <div class="tools">

            <div
              class="tools_card"
              v-for="tools in filterTools(item.id)"
              :key="tools.id"
            >
              <a
                class="tools_card_item"
                :href="tools.tools_url"
                target="_blank"
              >
                <div class="tools_card_top">
                  <div class="tools_cover_picture">
                    <img
                      :src="photoUrl(tools.tools_cover_picture)"
                      class="cover_picture"
                    />
                  </div>
                  <span class="tools_name">{{tools.tools_name}}</span>
                </div>
                <div class="tools_introduce">
                  {{tools.tools_introduce}}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Index>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import Index from '../components/Index.vue'
import { SELECTALLTOOLSCATEGORY, SELECTAllTOOLS } from '../api/api'
export default {
  components: { Index },
  setup() {
    onMounted(() => {
      selectAllToolsCategory()
      selectAllTools()
    })
    // 所有工具分类信息
    const allToolsCategory = ref([])
    // 查询所有工具分类
    const selectAllToolsCategory = () => {
      SELECTALLTOOLSCATEGORY().then((response) => {
        if (response.code === 0) {
          allToolsCategory.value = response.data
        }
      })
    }
    // 处理图片展示路径
    const photoUrl = (url) => {
      return (
        'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
        JSON.parse(url)[0]
      )
    }
    // 所有工具信息
    const allTools = ref([])
    // 查询所有工具
    const selectAllTools = () => {
      SELECTAllTOOLS().then((response) => {
        if (response.code === 0) {
          allTools.value = response.data
        }
      })
    }
    // 过滤工具信息
    const filterTools = (tools_category_id) => {
      return allTools.value
        .filter((item) => item.tools_category_id === tools_category_id)
        .reverse()
    }
    return {
      allToolsCategory,
      photoUrl,
      allTools,
      filterTools
    }
  }
}
</script>

<style>
.el-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
}
</style>
<style src="../css/tools.css" scoped>
</style>
